import { Stack } from '@mui/material';
import { Typography } from 'antd';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import LoginForm from '../components/app/utils/login-form/LoginForm';
import premastLoading from '../public/lottie/premast-loading.json';
const Lottie = dynamic(() => import('lottie-react'), { ssr: false });
const { Text } = Typography;

export default function Login() {
   const currentUser = useSWR('/api/user/current');
   const router = useRouter();

   if (currentUser.error) {
      return (
         <>
            <Head>
               <title>Login | Premast</title>
            </Head>
            <LoginForm login={true} />
         </>
      );
   }

   if (currentUser.isLoading) {
      return (
         <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100vh' }}>
            <Lottie animationData={premastLoading} style={{ width: 200, height: 200 }} />
            <Text type="secondary">Loading...</Text>
         </Stack>
      );
   }

   if (currentUser.data) {
      const { redirect, ...remainingQuery } = router.query;

      if (router.query.ai_assistant) {
         localStorage.setItem('ai_assistant', 'true');
      }
      const destination = redirect && redirect !== 'undefined' ? redirect : '/dashboard';

      // Rebuild the URL with the remaining query parameters
      const queryString = new URLSearchParams(remainingQuery).toString();
      const fullDestination = queryString ? `${destination}?${queryString}` : destination;
      router.push(fullDestination);

      return (
         <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100vh' }}>
            <Lottie animationData={premastLoading} style={{ width: 200, height: 200 }} />
            <Text type="secondary">Redirecting...</Text>
         </Stack>
      );
   }
}
